import React, {useState} from "react";
import Layout from "../../components/layout";
import Image  from "../../components/elements/Image";
import RamDesign from "../../components/models/ram/design";
import BlockColors from "../../components/models/ram/colors";
import { Title } from "../../styles/typography.styles";
import { CardTitle, CardText, Engine, EngineRow, EngineCard, EngineInfo, InteriorRow, InteriorCard2, Standard, Video } from "../../components/models/ram/ram.styles";
import { CardLine } from "../../components/models/models.styles";
import * as DesignJson from '../../data/ramDesign.json'
import * as ColorsJson from '../../data/laramieColors.json'
import LaramieOptions from "../../components/models/ram/laramie-options";
import LaramieStandard from "../../components/models/ram/laramie-standard";

const Bighorn = () => {
  const [chromeActive, setChromeActive] = useState(1)
  const [sportActive, setSportActive] = useState(1)
  const [blackActive, setBlackActive] = useState(1)

  const laramieDesign  = DesignJson.default.filter(item => item.model === "laramie");
  const chromeColors = ColorsJson.default.filter(item => item.model === "chrome");
  const sportColors = ColorsJson.default.filter(item => item.model === "sport");
  const blackColors = ColorsJson.default.filter(item => item.model === "black");

  return (
    <Layout title={'RAM Laramie'}>
      <main>
        <RamDesign data={laramieDesign} />

        <Title>Dostępne kolory</Title>
        <div className="container">
          <BlockColors title={'LARAMIE CHROME'}
                       data={chromeColors}
                       activeColor={chromeActive}
                       setActive={setChromeActive}/>
          <BlockColors title={'LARAMIE SPORT'}
                       data={sportColors}
                       activeColor={sportActive}
                       setActive={setSportActive}/>
          <BlockColors title={'LARAMIE BLACK'}
                       data={blackColors}
                       activeColor={blackActive}
                       setActive={setBlackActive}/>
        </div>

        <Engine>
          <div className="container">
            <Title color={'white'}>Silnik</Title>

            <EngineRow>
              <EngineCard>
                <Image fileName={'ram_engine_01@2x.jpg'} alt={"Silnik RAM 1500 Laramie"} />
                <CardTitle color={'white'}>SILNIK 5.7L V8 HEMI MDS VVT</CardTitle>
                <CardLine align={'flex-end'}/>
                <CardText color={'#a7a7a7'} align={'right'}>
                  Silnik HEMI V8 o pojemności 5,7 l zapewnia wyjątkową moc i obejmuje zmienny rozrząd zaworów oraz Multi-Displacement System z technologią oszczędzania paliwa. Zapewnia wydajne i mocne wrażenia z jazdy.
                </CardText>
                <EngineInfo>
                  <div className="title">395 (KM)</div>
                  <span>Koni mechanicznych</span>
                </EngineInfo>
                <EngineInfo>
                  <div className="title">556 (NM)</div>
                  <span>MAKSYMALNY MOMENT</span>
                </EngineInfo>
                <EngineInfo>
                  <div className="title">STANDARD BENZYNA</div>
                  <span>TYP PALIWA</span>
                </EngineInfo>
                <EngineInfo>
                  <div className="title">LPG, E85</div>
                  <span>ALTERNATYWNE PALIWO</span>
                </EngineInfo>
              </EngineCard>

              <EngineCard>
                <Image fileName={'ram_engine_02@2x.jpg'} alt={"Silnik RAM 1500 Laramie"} />
                <CardTitle color={'white'}>5.7L V8 HEMI MDS VVT ETORQUE ENGINE</CardTitle>
                <CardLine/>
                <CardText color={'#a7a7a7'}>
                  Silnik 5,7 l HEMI V8 MDS VVT z eTorque dodaje łagodny układ hybrydowy (eTorque) w celu poprawy oszczędności paliwa bez poświęcania mocy, momentu obrotowego lub zdolności. Silnik ten zapewnia dodatkowe 176 Nm momentu rozruchowego dzięki systemowi eTorque.
                </CardText>
                <EngineInfo>
                  <span>DODATKOWY MOMENT</span>
                  <div className="title">+176 (NM)</div>
                </EngineInfo>
                <EngineInfo>
                  <span>ZUŻYCIE PALIWA I EMISJA CO2</span>
                  <div className="title">-10%</div>
                </EngineInfo>
                <EngineInfo>
                  <span>TYP PALIWA</span>
                  <div className="title">STANDARD BENZYNA</div>
                </EngineInfo>
                <EngineInfo>
                  <span>ALTERNATYWNE PALIWO</span>
                  <div className="title">LPG, E85</div>
                </EngineInfo>
              </EngineCard>
            </EngineRow>
          </div>
        </Engine>

        <Title>wnętrze</Title>
        <div className="container">

          <InteriorRow>
            <InteriorCard2>
              <Image fileName={'BLACK-INTERIOR@2x.jpg'} alt={'RAM Laramie wnętrze'}/>
              <div className="title">Pięć czarnych, skórzanych foteli [GJX9] (Zdjęcie zawiera opcjonalny 12-calowy ekran)</div>
            </InteriorCard2>
            <InteriorCard2>
              <Image fileName={'BLACK-INTERIOR-6-SEATS@2x.jpg'} alt={'RAM Laramie wnętrze'}/>
              <div className="title">Sześć czarnych, skórzanych foteli [DPX9] (Zdjęcie zawiera opcjonalny 12-calowy ekran)</div>
            </InteriorCard2>
          </InteriorRow>

          <Title>Opcje dodatkowe</Title>

          <LaramieOptions/>
        </div>

        <Standard>
          <Title color={'white'}>STANDARD ZAWIERA</Title>

          <div className="container">
            <LaramieStandard/>
          </div>

        </Standard>

        <Video className={`container`}>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/-eOxOn9_Nes" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen></iframe>
        </Video>


      </main>
    </Layout>
  )
}

export default Bighorn;